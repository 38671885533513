import { createSlice } from '@reduxjs/toolkit'


export const globalControllerSlice = createSlice({
    name: 'globalController',
    initialState: {
        modalLoading: false,
    },
    reducers: {
        setModalLoading: (state, action) => {
            state.modalLoading = action.payload
        },
    }
})


export const { setModalLoading } = globalControllerSlice.actions

export default globalControllerSlice.reducer
import React from 'react'
import './PageLoading.css'
import Spinner from 'react-bootstrap/Spinner';

import all_logo from '../../assets/images/logo/all_logo.gif'

function PageLoading() {
  return (
    <div id='PageLoading'>
      <Spinner animation="border" role="status" style={{ color: '#3544aa' }}>
        <span className="visually-hidden">Loading...</span>
      </Spinner>
      {/* <img src={all_logo} /> */}
      {/* <div className="PageLoading-loading-spinner">
        <div className="PageLoading-loading-inner">
          <div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div>
        </div>
      </div> */}
    </div>
  )
}

export default PageLoading


import { configureStore } from '@reduxjs/toolkit'
import globalControllerSlice from "./globalControllerSlice"
import menuControllerSlice from "./menuControllerSlice"
import modalControllerSlice from "./modalControllerSlice"
import logger from 'redux-logger';
import nullCheckControllerSlice from './nullCheckControllerSlice';
import authControllerSlice from './authControllerSlice';
import deviceBoxOperationControllerSlice from './deviceBoxOperationControllerSlice';
import boxShipmentControllerSlice from './boxShipmentControllerSlice';
import companyControllerSlice from './companyControllerSlice';
import toastyControllerSlice from './toastyControllerSlice';
import globalInfoControllerSlice from './globalInformationControllerSlice';
import setupFilteredDataControllerSlice from './setupFilteredDataControllerSlice';
import ModificationStepsControllerSlice from './ModificationStepsControllerSlice';
import containersRealTimeStatusControllerSlice from './containersRealTimeStatusControllerSlice';
import systemCheckControllerSlice from './systemCheckControllerSlice';
import containerStatusInfoControllerSlice from './containerStatusInfoControllerSlice';
import antdModalControllerSlice from './antdModalControllerSlice';
import mapControllerSlice from './mapControllerSclice';
import chauffeurWatchControllerSlice from './chauffeurWatchControllerSlice';
import themeControllerSlice from './themeControllerSlice';
import googleMapsControllerSlice from './googleMapsControllerSlice';
import routeControlControllerSlice from './routeControlControllerSlice';
import searchToolsControllerSlice from './searchToolsControllerSlice';
import globalInfoCardControllerSlice from './globalInfoCardControllerSlice';
import routeManagementControllerSlice from './routeManagementControllerSlice';
import reportControllerSlice from './reportControllerSlice';
import homePageControllerSlice from './homePageControllerSlice';


export default configureStore({
  reducer: {
    globalController: globalControllerSlice,
    menuController: menuControllerSlice,
    modalController: modalControllerSlice,
    nullCheckController: nullCheckControllerSlice,
    authController: authControllerSlice,
    deviceBoxOperationController: deviceBoxOperationControllerSlice,
    boxShipmentController: boxShipmentControllerSlice,
    companyController: companyControllerSlice,
    toastyController: toastyControllerSlice,
    globalInfoController: globalInfoControllerSlice,
    setupFilteredDataController: setupFilteredDataControllerSlice,
    ModificationStepsController: ModificationStepsControllerSlice,
    containersRealTimeStatusController: containersRealTimeStatusControllerSlice,
    containerStatusInfoController: containerStatusInfoControllerSlice,
    antdModalController: antdModalControllerSlice,
    systemCheckController: systemCheckControllerSlice,
    mapController: mapControllerSlice,
    chauffeurWatchController: chauffeurWatchControllerSlice,
    themeController: themeControllerSlice,
    googleMapsController: googleMapsControllerSlice,
    routeControlController: routeControlControllerSlice,
    searchToolsController: searchToolsControllerSlice,
    globalInfoCardController: globalInfoCardControllerSlice,
    routeManagementController: routeManagementControllerSlice,
    reportController: reportControllerSlice,
    homePageController: homePageControllerSlice,
    
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
    logger
  }),
})